<template>
  <div>
    <div v-if="crow.show_label" class="mt-4 d-flex w-100">
      <div style="width: 5%"></div>
      <div style="width: 54%">
        <v-text-field
          dense
          filled
          hide-details
          color="cyan"
          solo
          flat
          placeholder="Label"
          v-model="crow.label"
          v-on:keyup="emitValue()"
          v-on:change="emitValue()"
        />
      </div>
      <div style="width: 41%"></div>
    </div>

    <div class="mt-4 d-flex w-100">
      <div style="width: 5%" class="text-center">
        <label class="px-0 mx-0 py-0 mt-3 my-0font-weight-600 font-size-18">
          {{ index + 1 }}.{{ cindex + 1 }}
        </label>
      </div>
      <div style="width: 54%">
        <v-layout>
          <v-flex md11>
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="ltxAdSelectList[productId]"
              placeholder="Additional Work"
              solo
              flat
              hide-details
              :readonly="hasLevelThree"
              class="width-100"
              v-model="crow.product_id"
              v-on:change="
                updateAdwork($event, rowUuid, cindex);
                emitValue();
              "
            />
          </v-flex>
          <v-flex md1>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="height: 40px"
                  v-on:click="
                    crow.show_label = !crow.show_label;
                    emitValue();
                  "
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="w-100"
                  depressed
                  color="cyan white--text"
                >
                  <v-icon v-if="crow.show_label">mdi-minus</v-icon>
                  <v-icon v-else>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span v-if="crow.show_label">Hide Area</span>
              <span v-else>Show Area</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          placeholder="Description"
          solo
          flat
          class="my-2"
          hide-details
          row-height="10"
          v-model="crow.description"
          v-on:keyup="emitValue()"
          v-on:change="emitValue()"
        />
      </div>
      <div style="width: 12%">
        <v-layout class="line-item-uom">
          <v-flex style="width: 60px">
            <v-text-field
              dense
              filled
              hide-details
              color="cyan"
              solo
              flat
              type="number"
              v-on:keypress="is_number($event)"
              placeholder="Quantity"
              v-model="crow.quantity"
            />
          </v-flex>
          <v-flex style="width: 100px">
            <v-select
              :items="ltxUomList"
              dense
              filled
              flat
              hide-details
              solo
              v-model="crow.uom"
              placeholder="UOM"
              label="UOM"
              color="cyan"
              item-color="cyan"
              v-on:change="emitValue()"
            />
          </v-flex>
        </v-layout>
      </div>
      <div style="width: 12%">
        <v-text-field
          dense
          filled
          hide-details
          color="cyan"
          solo
          flat
          prefix="$"
          type="number"
          v-on:keypress="is_number($event)"
          placeholder="Rate"
          v-model="crow.rate"
        />
        <p class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500">
          Supplier Cost: <br />
          <template v-if="crow.edit_supplier_cost">
            <v-text-field
              dense
              :id="`crow-supplier-price-${index}-${cindex}`"
              :ref="`crow-supplier-price-${index}-${cindex}`"
              filled
              hide-details
              color="cyan"
              solo
              flat
              prefix="$"
              type="number"
              v-on:keypress="is_number($event)"
              placeholder="Supplier Cost"
              v-model="crow.company_cost"
              v-on:change="crow.edit_supplier_cost = false"
              v-on:blur="crow.edit_supplier_cost = false"
              v-on:keyup="emitValue()"
            />
          </template>
          <template v-else>
            <div class="d-flex align-center">
              <label class="m-0 p-0 font-weight-600">{{
                formatMoney(crow.company_cost)
              }}</label>
              <v-btn
                v-on:click="
                  crow.edit_supplier_cost = true;
                  editChildSupplier(index, cindex);
                "
                small
                icon
                color="cyan"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </p>
        <p class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500">
          Profit:
          <label class="m-0 p-0 font-weight-600">{{
            formatMoney(crow.profit)
          }}</label>
        </p>
        <p class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500">
          Markup (in %):
          <label class="m-0 p-0 font-weight-600">
            {{ formatNumber(crow.markup_percent) }} %</label
          >
        </p>
      </div>
      <div style="width: 17%">
        <v-text-field
          dense
          filled
          hide-details
          color="cyan"
          solo
          flat
          readonly
          class="has-delete-outer-icon"
          append-outer-icon="mdi-delete"
          v-on:click:append-outer="deleteChildRow(rowUuid, cindex)"
          placeholder="Total"
          :value="formatMoney(crow.total)"
        />
        <p class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500">
          Total Supplier Cost:<br /><label class="m-0 p-0 font-weight-600">
            {{ formatMoney(crow.total_company_cost) }}
          </label>
        </p>
        <p class="w-100 mt-11 mb-0 text-left font-size-16 font-weight-500">
          Profit (in %):
          <label class="m-0 p-0 font-weight-600"
            >{{ formatNumber(crow.profit_percent) }} %</label
          >
        </p>
      </div>
    </div>

    <div
      class="d-flex w-100 pb-2"
      :class="{ 'custom-border-bottom': hasLevelThree }"
    >
      <div style="width: 5%"></div>
      <div style="width: 18%; position: relative">
        <v-btn
          :disabled="!crow.product_id"
          v-on:click="addLevel2ChildItem(rowUuid, crow.uuid)"
          small
          depressed
          color="cyan white--text"
          class="custom-bold-button white--text"
        >
          <v-icon>mdi-plus</v-icon> Add Level 2
        </v-btn>
        <template v-if="!hasLevelThree && nextIndex">
          <v-btn
            :disabled="!productId"
            v-on:click="$emit('add-child', { rowUuid, productId })"
            small
            depressed
            style="position: absolute; bottom: 0; left: 0"
            color="cyan white--text"
            class="custom-bold-button white--text"
          >
            <v-icon>mdi-plus</v-icon> Add Additional Work (Same Category)
          </v-btn>
        </template>
      </div>
      <div class="text-right pr-4" style="width: 18%">
        <v-checkbox
          v-if="ltxSummaryReport"
          hide-details
          class="my-2 py-0 d-inline-block"
          color="cyan"
          label="Is Removed?"
          :true-value="1"
          :false-value="0"
          v-model="crow.strike"
          v-on:change="
            crow.is_optional = 0;
            setChildRemoved($event, rowUuid, crow.uuid);
            emitValue();
          "
        />
      </div>
      <div class="text-right pr-4" style="width: 18%">
        <v-checkbox
          hide-details
          class="my-2 py-0 d-inline-block"
          color="cyan"
          label="Is Optional?"
          :true-value="1"
          :false-value="0"
          v-model="crow.is_optional"
          v-on:change="
            crow.strike = 0;
            setChildOptional($event, rowUuid, crow.uuid);
            emitValue();
          "
        />
      </div>
      <div style="width: 41%">
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          placeholder="Internal Note"
          solo
          flat
          class="my-2"
          hide-details
          v-model="crow.internal_note"
          v-on:change="emitValue()"
          v-on:keyup="emitValue()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import {
  ADD_LEVEL2_CHILD_ITEM,
  EMIT_VALUE,
  UPDATE_ADWORK,
  SET_CHILD_OPTIONAL,
  SET_CHILD_REMOVED,
  DELETE_ADWORK,
} from "@/core/services/store/line.item.module";

export default {
  mixins: [CommonMixin],
  data() {
    return {};
  },
  props: {
    crow: {
      type: Object,
      default() {
        return {};
      },
    },
    cindex: {
      type: Number,
      default: 0,
    },
    productId: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    nextIndex: {
      type: Boolean,
      default: false,
    },
    rowUuid: {
      type: String,
      default: null,
    },
    hasLevelThree: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    editChildSupplier(index, cindex) {
      setTimeout(() => {
        this.$refs[
          `crow-supplier-price-${index}-${cindex}`
        ].$refs.input.focus();
      }, 100);
    },
    setChildOptional(val, rowUuid, uuid) {
      this.$store.dispatch(SET_CHILD_OPTIONAL, { val, rowUuid, uuid });
    },
    setChildRemoved(val, rowUuid, uuid) {
      this.$store.dispatch(SET_CHILD_REMOVED, { val, rowUuid, uuid });
    },
    updateAdwork(productId, rowUuid, index) {
      this.$store.dispatch(UPDATE_ADWORK, { productId, rowUuid, index });
    },
    emitValue() {
      this.$store.dispatch(EMIT_VALUE);
    },
    addLevel2ChildItem(rowUuid, uuid) {
      this.$store.dispatch(ADD_LEVEL2_CHILD_ITEM, { rowUuid, uuid });
    },
    deleteChildRow(rowUuid, index) {
      this.$store.dispatch(DELETE_ADWORK, { rowUuid, index });
    },
  },
  computed: {
    ...mapGetters([
      "ltxAdSelectList",
      "ltxUomList",
      "ltxLevel2ChildLineItem",
      "ltxSummaryReport",
    ]),
  },
};
</script>
